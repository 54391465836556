/* Core Styles */
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #1e1e1e;
  color: #a0a0a0;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  border-top: 1px solid #3a3a3a;
}


.footer-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.footer-links a {
  color: #1e1ecb;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #1d9bf0;
  text-decoration: underline;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Layout Containers */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Grid Responsiveness */
@media (min-width: 576px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 992px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Image and Video Containers */
.trunk-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: 10px auto;
}

.trunk-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.trunk {
  max-width: 1200px;
  margin: 0 auto;
}

.branch {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

/* Header Styling */
.header {
  width: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
  font-size: 2rem;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.header h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
}

/* Video Responsiveness */
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  margin: 10px auto;
}

.video-container iframe,
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: none;
}

/* Image Container */
.img-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

/* Small Screen Adjustments */
@media (max-width: 576px) {
  .header {
    font-size: 1.2rem;
    padding: 0.5rem;
    margin-bottom: 5px;
  }
  .header h1 {
    font-size: 1.5rem;
  }
  .container {
    padding: 5px;
  }
  .grid-container {
    padding: 5px;
    gap: 5px;
  }
  .branch {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
  .footer-links {
    flex-direction: column;
    gap: 5px;
  }
  .footer-links a {
    font-size: 12px;
  }
  .App-footer {
    padding: 5px;
  }
  .video-container {
    margin: 5px auto;
  }
  .trunk-container {
    margin: 5px auto;
  }
}