.header {
    background-color: #1a1a1a;
    padding: 15px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .header-logo {
    width: 180px;
    height: auto;
  }
  
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    transition: color 0.3s ease, background 0.3s ease;
    border-radius: 5px;
  }
  
  .nav-links a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #f8b400;
  }
  